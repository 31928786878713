export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiences"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "portuguese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm"])},
  "knowing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowing"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
  "about-me-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a senior software engineer passionate about creating innovative and efficient solutions. With a solid experience in distributed systems architecture and an extensive career in challenging projects, I am constantly supporting companies to develop even more incredible software."])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
  "lots-of-history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lots of history"])},
  "developing-solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developing solutions"])},
  "supporting-evolution-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporting evolution of"])},
  "big": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "dont-hesitate-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't hesitate to"])},
  "talk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talk"])},
  "to-me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to Me"])},
  "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
  "making-a-difference-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making a difference in"])},
  "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My"])},
  "sr-software-engineer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sr. Sofware Engineer"])},
  "sr-fullstack-developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sr. Fullstack Developer"])},
  "owner-and-developer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner & Developer"])},
  "trainee-programmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainee Programmer"])},
  "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
  "apr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
  "jun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
  "sep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
  "mar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
  "oct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
  "aug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
  "view-cv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View CV"])},
  "come-down": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come down"])},
  "native": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Native"])},
  "basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])},
  "main-technologies-that": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main technologies that"])},
  "have": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have"])},
  "made-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made with"])},
  "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in"])}
}