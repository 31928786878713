<template>
  <header>
    <h1 class="title">{{ $t('welcome') }}<br /><p>{{ $t('im') }} <b>{{ iam }}<span class="cursor">|</span></b></p></h1>
    <ul class="socialmedia">
      <li><a href="https://github.com/lucasmattosdev" aria-label="GitHub" rel="noopener" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/></svg></a></li>
      <li><a href="https://br.linkedin.com/in/lucasmattosdev" aria-label="Linkedin" rel="noopener" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg></a></li>
      <li><a href="https://api.whatsapp.com/send?phone=+5548999494828&amp;text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20seu%20site." aria-label="Whatsapp" rel="noopener" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg></a></li>
    </ul>
    <br />
    <br />
    <a href="./Curriculo-LucasMattos.pdf" target="_blank" :aria-label="$t('view-cv')" rel="noopener" class="button curriculum">{{ $t('view-cv') }}</a>
    <a href="/" class="button scroll" :aria-label="$t('come-down')" @click="(e) => this.goTo(e, 'aboutme')">
      <svg fill="#666" height="15px" width="15px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
        <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
        c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
        s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
      </svg>
    </a>
  </header>
</template>

<script>
export default {
  name: 'lm-introduction',
  data() {
    return {
      iam: ''
    }
  },
  created() {
    this.changeIam();
  },
  methods: {
    async changeIam() {
      await this.writeIam('Lucas Mattos');
      await new Promise(resolve => setTimeout(resolve, 4000));
      await this.writeIam('Software Engineer');
      await new Promise(resolve => setTimeout(resolve, 3000));
      this.changeIam();
    },
    async writeIam(value) {
      document.getElementsByClassName('cursor')[0]?.classList?.remove('active');
      while (this.iam != '') {
        this.iam = this.iam.slice(0, -1);
        await new Promise(resolve => setTimeout(resolve, 30));
      }
      while (this.iam != value) {
        this.iam = value.slice(0, this.iam.length + 1);
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      document.getElementsByClassName('cursor')[0]?.classList?.add('active');
    },
    goTo(e, name) {
      e.preventDefault();

      window.scrollTo({
        top: document.getElementById(name).offsetTop - 120,
        behavior: "smooth"
      });
    },
  }
};
</script>

<style scoped>
  header::before {
    background: url('./assets/home-bg.webp');
    content: "";
    position: fixed;
    z-index: -1;
    top: 0; left: 0;
    width: 100%;
    height: 100svh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    background-attachment: scroll;
    filter: brightness(0.3);
    min-height: 550px;
  }
  .no-webp header::before {
    background: url('./assets/home-bg.jpg');
  }
  header {
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--white-000);
    text-align: center;
    min-height: 550px;
    position: relative;
  }
  h1 {
    font-weight: 400;
    margin-top: -1rem;
  }
  h1 p {
    letter-spacing: -3px;
    font-size: 4rem;
    font-weight: 500;
  }
  .button {
    box-shadow: 0 0 4rem 0px var(--gray-900);
    padding: 1rem 1.5rem;
    background: transparent;
    border: 2px solid var(--white-000);
    border-radius: 50px;
    color: var(--white-000);
    font-size: 1rem;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  .button:hover {
    background: var(--white-000);
    color: var(--gray-900);
  }
  .socialmedia {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  .socialmedia a {
    display: flex;
    padding: 10px;
    margin: 0 5px;
    border-radius: 20px;
    opacity: 0.9;
    transition: background-color 0.2s ease, opacity 0.3s ease;
  }
  .socialmedia a:hover {
    opacity: 1;
    background-color: #ffffff21;
  }
  .socialmedia svg {
    fill: var(--white-000);
  }
  .scroll {
    bottom: 3rem;
    position: absolute;
    padding: 25px 6px 2px;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s ease infinite;
  }
  @keyframes bounce {
    0%,20%,53%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
        animation-timing-function: cubic-bezier(.215,.61,.355,1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    40%,43% {
        -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        -webkit-transform: translate3d(0,-30px,0);
        transform: translate3d(0,-30px,0)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        animation-timing-function: cubic-bezier(.755,.05,.855,.06);
        -webkit-transform: translate3d(0,-15px,0);
        transform: translate3d(0,-15px,0)
    }

    90% {
        -webkit-transform: translate3d(0,-4px,0);
        transform: translate3d(0,-4px,0)
    }
  }
  .cursor.active {
    -webkit-animation: blink 1s infinite;
    animation: blink 1s ease infinite;
  }
  @keyframes blink {
    50%,90% {
        opacity: 1;
    }

    55%, 85% {
      opacity: 0;
    }
  }
  .curriculum {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 768px) { 
    h1 p {
      font-size: 3rem;
      letter-spacing: -2px;
    }
    .button {
      padding: 0.8rem 1.2rem;
      font-size: 0.8rem;
    }
    .button:hover {
      background: unset;
      color: var(--white-000);
    }
    .scroll {
      padding: 20px 6px 2px;
    }
  }

  @media screen and (max-width: 480px) { 
    h1 {
      font-size: 1.6rem;
    }
    h1 p {
      font-size: 2rem;
      letter-spacing: -1px;
    }
  }
</style>
