<template>
  <section>
    <div class="content w-100">
      <div>
        <div class="prefix-section-title">{{ $t('dont-hesitate-to') }}</div>
        <h2 class="section-title"><b>{{ $t('talk') }}</b> {{  $t('to-me') }}</h2>
      </div>
      <div class="options">
        <div class="option" v-check-visible>
          <a href="http://api.whatsapp.com/send?1=pt_BR&phone=5548999494828" rel="noopener" target="_blank">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 48 48"><path d="M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z"></path></svg>
            </div>
            <div class="title">
              Whatsapp
            </div>
            <div class="contact">
              +55 (48) 99949-4828
            </div>
          </a>
        </div>
        <div class="option" v-check-visible>
          <a href="mailto:lucasmattosdev@gmail.com" rel="noopener" target="_blank">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 128 96" id="email"><g data-name="Layer 2"><path d="M0 11.283V8a8 8 0 0 1 8-8h112a8 8 0 0 1 8 8v3.283l-64 40zm66.12 48.11a4.004 4.004 0 0 1-4.24 0L0 20.717V88a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8V20.717z"></path></g></svg>
            </div>
            <div class="title">
              {{ $t('mail') }}
            </div>
            <div class="contact">
              contato@lucasmattos.dev
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'lm-contact'
};
</script>

<style scoped>
section {
  background: var(--gray-200);
  box-shadow: inset 0 0px 80px -40px var(--gray-300);
}
.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  text-wrap: nowrap;
  margin: 2rem 0 1rem;
  row-gap: 2rem;
  column-gap: 3rem;
}
.title {
  color: var(--gray-700);
}
svg {
  fill: var(--gray-700);
}
.option {
  background: #fff;
  padding: 2rem 3rem;
  border-radius: 1rem;
  transition:all .1s ease;
  flex: 1 1 0;
  box-shadow: 0 0px 50px -30px var(--gray-300);
}
.icon {
  margin: 0.5rem;
}
.title {
  font-weight: bold;
  font-size: 1.2rem;
}
.contact {
  color: var(--gray-500);
  font-size: 0.9rem;
}
@media screen and (min-width: 1024px) {
  .option:hover, .option.visible {
    transform: scale(1.05);
  }
}
@media screen and (max-width: 480px) {
  .option {
    padding: 1.5rem 1rem;
  }
}
</style>
